<template>
  <div class="content">
    <template>
      <v-card
        :style="
          $vuetify.breakpoint.xs
            ? 'margin: 4px'
            : 'margin: 0 18px 0 18px'
        "
      >
        <base-loading v-if="loading" />

        <div v-else>
          <crud-list
            v-model="unidades"
            :headers="headers"
            :actions="actions"
            :sort-by="['codigo','nome']"
            :sort-desc="[false]"
            export-pdf-xlsx
          >
            <template v-slot:btnCadastro>
              <v-col>
                <v-btn
                  block
                  class="text-none text-white"
                  color="primary"
                  title="Cadastrar nova unidade"
                  rounded
                  @click="$router.push({ path: 'unidadesmedida/cadastrar' })"
                >
                  <v-icon
                    dark
                    left
                  >
                    mdi-file-plus
                  </v-icon>
                  Cadastrar
                </v-btn>
              </v-col>
            </template>
          </crud-list>
        </div>
      </v-card>
    </template>
  </div>
</template>
<script>
  import UnidadesStore, { BOOTSTRAP, INATIVAR_UNIDADE } from '@/store/modules/unidades'
  import { mapState, mapActions } from 'vuex'
  export default {
    data () {
      return {
        actions: [
          {
            title: 'Editar unidade',
            color: 'info darken-3',
            click: item => this.editarUnidade(item),
            icon: 'mdi-pencil',
          },
          {
            title: 'Excluir unidade',
            color: 'red darken-3',
            click: item => this.INATIVAR_UNIDADE(item),
            icon: 'mdi-trash-can-outline',
          },
        ],
        headers: [
          { text: '', value: 'actions', sortable: false, groupable: false, floatingfilter: false, width: '40px' },
          {
            align: 'left',
            sortable: true,
            floatingfilter: true,
            groupable: false,
            text: 'Nome',
            value: 'nome',
            width: 'auto',
            report: true,
          },
          {
            align: 'left',
            sortable: true,
            groupable: false,
            floatingfilter: true,
            text: 'Sigla',
            value: 'sigla',
            width: 'auto',
            report: true,
          },
        ],
      }
    },
    computed: {
      ...mapState('unidades', ['loading', 'unidades']),
    },
    async created () {
      if (!this.$store.hasModule('unidades')) { this.$store.registerModule('unidades', UnidadesStore) }
      this.BOOTSTRAP()
    },
    methods: {
      ...mapActions('unidades', [BOOTSTRAP, INATIVAR_UNIDADE]),
      editarUnidade ({ id }) {
        this.$router.push({
          path: `/${this.$user.get().role}/cdc/unidadesmedida/alterar`,
          query: { id },
        })
      },
    },
  }
</script>
